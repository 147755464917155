<template>
  <div class="dashboard-container">
    <h1 class="dashboard__title">Панель управления</h1>
    <!--<component :is="currentRole"/>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import adminDashboard from './admin';
// import editorDashboard from './editor';

export default {
  name: 'Dashboard',
  // components: { adminDashboard, editorDashboard },
  data() {
    return {
      currentRole: 'adminDashboard',
    }
  },
  computed: {
    ...mapGetters([
      'roles',
    ]),
  },
  created() {
    if (!this.roles.includes('admin')) {
      this.currentRole = 'editorDashboard'
    }
  },
}
</script>
<style lang="sass">
.dashboard__title
  padding-left: 30px
</style>
